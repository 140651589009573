import { createSlice } from "@reduxjs/toolkit";
import { navigate } from "gatsby";

export const questionsSlice = createSlice({
  name: "questions",
  initialState: {
    index: 0,
    data: [],
    currentData: null,
    length: 0,
    answers: [],
    isLoading: true,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      state.currentData = state.data[0];
      state.length = state.data.length;
    },
    setNextData: (state, action) => {

      
      if (state.index < state.length - 1) {

        
        state.answers = [
          ...state.answers,
          {
            id: action.payload.id,
            answer: action.payload.answer,
            questionTitle: state.currentData.title,
          },
        ];

        state.index += 1;

        state.currentData = state.data[state.index];
      } else {
        state.answers = [
          ...state.answers,
          {
            id: action.payload.id,
            answer: action.payload.answer,
            questionTitle: state.currentData.title,
          },
        ];
        navigate("/result");
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setData, setNextData, setIsLoading } = questionsSlice.actions;

export default questionsSlice.reducer;
